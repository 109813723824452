import React, { useEffect, useMemo, useState } from 'react'
import TopBanner from './top_banner';
import { Layout } from 'antd';
import globalHelper from '../../helpers/functions/global.helper';
import MasterHeader from './master_header';
import { useSelector } from 'react-redux';
import TopHeaderBannerWrapper from './top_banner_open_date';
import TopBannerOpenDate from './top_banner_open_date';
import TopBannerNormalTicket from './top_banner_normal_ticket';

const { Header, } = Layout;
const TopHeader = () => {
    const bannerHeight = globalHelper?.isMobileScreen() ? 100 : 40;
    const ticketRedux = useSelector(state => state?.ticket);
    const purchaseRedux = useSelector(state => state?.purchase);
    const park = ticketRedux?.park;

    const [state, setState] = useState({
        is_banner : false,
    })

    // const bannerQuill = useMemo(() => {
    //     let banner_quill;
    //     try{
    //         banner_quill = JSON.parse(park?.banner_text)
    //     }catch(err){

    //     }
    //     return banner_quill;
    // }, [park])

    useEffect(() => {
        // set header height
        document.documentElement.style.setProperty('--header-height', `${(64 + (bannerHeight ?? 0))}px`);
    }, [])

    return (
        <>
            {
                purchaseRedux?.open_date ?
                (
                    <>
                        <TopBannerOpenDate bannerHeight={bannerHeight} park={park} setBanner={(is_banner) => setState(state => ({...state, is_banner,}))} />
                    </>
                )
                :
                (
                    <>
                        <TopBannerNormalTicket bannerHeight={bannerHeight} park={park} setBanner={(is_banner) => setState(state => ({...state, is_banner,}))} />
                        {/* {
                            bannerQuill && (
                                <>
                                    <TopBanner height={bannerHeight} bannerQuill={bannerQuill} />
                                </>
                            )
                        } */}
                    </>
                )
            }
            
            {console.log(state?.is_banner)}
            <Header
            style={{
                background : '#fff', 
                border:'1px solid #edf2f7', 
                position: 'sticky', 
                top: (state?.is_banner ? bannerHeight : 0), 
                zIndex: 100, 
                width: '100%',
                ...(globalHelper?.isMobileScreen() && { padding : '0 12px' }),
            }}
            >
                <MasterHeader />
            </Header>
        </>
    )
}

export default TopHeader;